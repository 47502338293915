const NavLive = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M13.444 3.66797H2.90234C1.76326 3.66797 0.839844 4.59138 0.839844 5.73047V14.2721C0.839844 15.4112 1.76326 16.3346 2.90235 16.3346H13.444C14.5831 16.3346 15.5065 15.4112 15.5065 14.2721V5.73047C15.5065 4.59138 14.5831 3.66797 13.444 3.66797Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1706 6.47668C19.1706 6.03646 18.6545 5.79869 18.3199 6.08477L15.5039 8.49242V11.5113L18.3199 13.919C18.6545 14.205 19.1706 13.9673 19.1706 13.5271V6.47668Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default NavLive
